import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography/Typography";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import UserContext from "../src/UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(4),
        },
    },
}));

function SignIn(props) {
  const router = new useRouter();
  let { keycloakstate, setKeycloakstate } = useContext(UserContext);
  const classes = useStyles();

  if (keycloakstate.authenticated === true) {
    router.push("/");
  }

  return (
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="center" m={1} p={1}>
        <Typography variant="h6" noWrap>
          Παρακαλώ περιμένετε
        </Typography>
        <div className={classes.root}>
          <CircularProgress />
        </div>
      </Box>
    </Container>
    // <Grid container component="main" direction="column">
    //     <CssBaseline />
    //     <Grid item container justify="center" alignItems="center">
    //         <Grid item xs={12} sm={10}>
    //             <Header />
    //         </Grid>
    //     </Grid>
    //     <Grid item container justify="center" alignItems="center">
    //         <Grid item sm={10}>
    //             <Content />
    //         </Grid>
    //     </Grid>
    // </Grid>
  );
}

export default SignIn;
